// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.np7Y0MkHTIwcSHUGIIMx{position:absolute;width:100%;height:100%;background:rgba(.1,.1,.1,.6);display:grid;font-family:"acumin-pro";top:0}.np7Y0MkHTIwcSHUGIIMx .EX4YG00dguWCkO8K4BGA{align-self:center;justify-self:center;min-height:140px;max-width:400px;border-radius:10px;background:#fff;padding:10px;display:grid;grid-template-columns:100%;grid-template-rows:1fr 50px}.np7Y0MkHTIwcSHUGIIMx .EX4YG00dguWCkO8K4BGA .CScU0XzmVIJiSJZPuZ0n{grid-row:1;grid-column:1;margin-top:20px;padding:10px;font-size:18px;color:#000}.np7Y0MkHTIwcSHUGIIMx .EX4YG00dguWCkO8K4BGA .x1oa1806M24Am9GoCmnw{grid-row:2;grid-column:1;display:grid;grid-template-rows:100%;grid-template-columns:100%;color:#fff}.np7Y0MkHTIwcSHUGIIMx .EX4YG00dguWCkO8K4BGA .x1oa1806M24Am9GoCmnw .DY4D4PjfxnzHWsMdzE9g{cursor:pointer;justify-self:center;background:green;min-width:80px;justify-content:center;display:grid;align-content:center;align-self:center;height:35px;border-radius:10px}.np7Y0MkHTIwcSHUGIIMx .EX4YG00dguWCkO8K4BGA .x1oa1806M24Am9GoCmnw .DY4D4PjfxnzHWsMdzE9g:hover{opacity:.8}.np7Y0MkHTIwcSHUGIIMx .EX4YG00dguWCkO8K4BGA .x1oa1806M24Am9GoCmnw .DY4D4PjfxnzHWsMdzE9g.CB6qM9GpeI7TwYdoxBCD{background:red}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/MessageDialog.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,4BAAA,CACA,YAAA,CACA,wBAAA,CACA,KAAA,CAEA,4CACI,iBAAA,CACA,mBAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CAEA,YAAA,CACA,0BAAA,CACA,2BAAA,CAEA,kEACI,UAAA,CACA,aAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CACA,UAAA,CAGJ,kEACI,UAAA,CACA,aAAA,CACA,YAAA,CACA,uBAAA,CACA,0BAAA,CACA,UAAA,CAEA,wFACI,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,cAAA,CACA,sBAAA,CACA,YAAA,CACA,oBAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CAEA,8FACI,UAAA,CAGJ,6GACI,cAAA","sourceRoot":""}]);
// Exports
var _1 = `np7Y0MkHTIwcSHUGIIMx`;
export { _1 as "dialog-container" };
export var dialog = `EX4YG00dguWCkO8K4BGA`;
var _2 = `CScU0XzmVIJiSJZPuZ0n`;
export { _2 as "dialog-message" };
var _3 = `x1oa1806M24Am9GoCmnw`;
export { _3 as "dialog-buttons" };
var _4 = `DY4D4PjfxnzHWsMdzE9g`;
export { _4 as "dialog-button-ok" };
export var error = `CB6qM9GpeI7TwYdoxBCD`;
export default ___CSS_LOADER_EXPORT___;
