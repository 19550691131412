// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xB5vPIJoIWKvnlA71PfB{width:40px;grid-template-rows:0px 40px 0px;border-radius:40px;transform:translateY(-7px)}.XzMt3EHEZT1uf8cfNiYm{display:none}.Dd70KUYJyN1ZmFsPsOSB{transform:translateY(7px)}.Bb6S3K6trmfZVngivGsR{border-radius:40px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeRenderGraphEditor/dist/graphSystem/display/elbowDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,+BAAA,CACA,kBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,kBAAA","sourceRoot":""}]);
// Exports
export var elbowBlock = `xB5vPIJoIWKvnlA71PfB`;
export var hidden = `XzMt3EHEZT1uf8cfNiYm`;
export var translatedConnections = `Dd70KUYJyN1ZmFsPsOSB`;
export var roundSelectionBorder = `Bb6S3K6trmfZVngivGsR`;
export default ___CSS_LOADER_EXPORT___;
