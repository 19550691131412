// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r4ahfNLNjd1vdkE_hQl2{display:grid}.r4ahfNLNjd1vdkE_hQl2 .gv4Ab2PP_HaGmyz2gB8m{width:100%;height:100%;padding:0;margin:0;background-color:#444;border:0px;overlay:hidden}.r4ahfNLNjd1vdkE_hQl2 .gv4Ab2PP_HaGmyz2gB8m.eEEMUXTISbaTT2tPb9l7{cursor:row-resize}.r4ahfNLNjd1vdkE_hQl2 .gv4Ab2PP_HaGmyz2gB8m.ejkXNkJNa6NmG6WeqSjb{cursor:col-resize}.r4ahfNLNjd1vdkE_hQl2 .gv4Ab2PP_HaGmyz2gB8m:hover{background-color:#666}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/split/splitContainer.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAEA,4CACI,UAAA,CACA,WAAA,CACA,SAAA,CACA,QAAA,CACA,qBAAA,CACA,UAAA,CACA,cAAA,CAEA,iEACI,iBAAA,CAGJ,iEACI,iBAAA,CAGJ,kDACI,qBAAA","sourceRoot":""}]);
// Exports
var _1 = `r4ahfNLNjd1vdkE_hQl2`;
export { _1 as "split-container" };
export var splitter = `gv4Ab2PP_HaGmyz2gB8m`;
export var vertical = `eEEMUXTISbaTT2tPb9l7`;
export var horizontal = `ejkXNkJNa6NmG6WeqSjb`;
export default ___CSS_LOADER_EXPORT___;
