// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qETWBczo3lJyvwkz5e2x{grid-row:2;min-height:34px;text-align:center;font-size:18px;font-weight:bold;margin:0 10px 5px;display:grid;align-content:center}.qETWBczo3lJyvwkz5e2x.dPL7NljKcYaRmncMBRC1{font-size:17px}.ZicBnZZEnLMg723zgRkR{border-color:#464348;background:#464348}.OoXfaKUy1Mi2KdrE0P0s{border-color:#66491b;background:#66491b}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeRenderGraphEditor/dist/graphSystem/display/inputDisplayManager.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,oBAAA,CAEA,2CACI,cAAA,CAIR,sBACI,oBAAA,CACA,kBAAA,CAGJ,sBACI,oBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
var _1 = `qETWBczo3lJyvwkz5e2x`;
export { _1 as "input-block" };
var _2 = `dPL7NljKcYaRmncMBRC1`;
export { _2 as "small-font" };
export var contextual = `ZicBnZZEnLMg723zgRkR`;
export var inspector = `OoXfaKUy1Mi2KdrE0P0s`;
export default ___CSS_LOADER_EXPORT___;
