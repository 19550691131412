// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LcAfLURgMF6_xQIp9xW5{stroke-width:4px}.LcAfLURgMF6_xQIp9xW5.zf49BMaByhYYIAbbyPJI{stroke:#fff !important;stroke-dasharray:10,2}.jrnrI_y4VbpSgvxxjfy2{filter:brightness(1.5);stroke-width:6px}.eVGcF_1cKpBdhuYPamV_{pointer-events:all;stroke-width:16px;opacity:0;transition:opacity 75ms;stroke:rgba(0,0,0,0);cursor:pointer}.eVGcF_1cKpBdhuYPamV_:hover,.eVGcF_1cKpBdhuYPamV_.zf49BMaByhYYIAbbyPJI{stroke:#fff !important;opacity:.4}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/nodeGraphSystem/nodeLink.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CACA,2CACI,sBAAA,CACA,qBAAA,CAIR,sBACI,sBAAA,CACA,gBAAA,CAGJ,sBACI,kBAAA,CACA,iBAAA,CACA,SAAA,CACA,uBAAA,CACA,oBAAA,CACA,cAAA,CAEA,uEAEI,sBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var link = `LcAfLURgMF6_xQIp9xW5`;
export var selected = `zf49BMaByhYYIAbbyPJI`;
var _1 = `jrnrI_y4VbpSgvxxjfy2`;
export { _1 as "target-candidate" };
var _2 = `eVGcF_1cKpBdhuYPamV_`;
export { _2 as "selection-link" };
export default ___CSS_LOADER_EXPORT___;
